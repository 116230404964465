<template>
    <zero-payment :program_id="6"></zero-payment>
</template>
<script>
import ZeroPayment from '@/views/commons/components/zero-payment/ZeroPayment.vue'
export default {
    components:{
        ZeroPayment
    },
}
</script>